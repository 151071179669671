html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  min-height: 100vh;
}

*, *:before, *:after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}


body {
  margin: 0;
  padding: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

button{
  font-family: "Lato";
}

a.active {
  color: #212128 !important;
}

a {
  color: inherit; /* blue colors for links too */
  text-decoration: inherit; /* no underline */
}

textarea {
  border: none;
  overflow: auto;
  outline: none;

  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;

  resize: none;
}

.logInActive{
  color:#67B7EB;
  border-color: #67B7EB;
}

::placeholder{
opacity: 0.5;
font-size: 12px;
color: #555567;
letter-spacing: -0.5px;
vertical-align: middle;
text-transform: capitalize;
display: table-cell;
}

.show-password :hover{
  color:#67B7EB;
}

textarea::placeholder{
  /* padding-top: 20px; */
  opacity: 0.5;
  font-size: 12px;
  color: #555567;
  letter-spacing: -0.5px;
  /* line-height: 18px; */
  text-transform: none !important;
}
.inv textarea::placeholder {
  font-size: 16px !important;
}

.deal-search::placeholder {
  font-size: 12px;
}

.my-cal{
  color: #C7C7D3;
    font-family: SanFranciscoText-Regular, lato !important;
    margin-top: 12px;
    padding: 16px 30px 0 30px;
    border: none !important;
    background: none !important;
}

.react-calendar__month-view__days__day--weekend {
  color: #67B7EB !important;
}

.react-calendar__tile--active {
  background: #67B7EB !important;
  color: white !important;
}

.react-calendar__tile--hasActive {
  background: #67B7EB !important;
  color: white !important;
}

.react-calendar__tile{
  color: #212128;
}

.react-calendar__navigation{
  color: #212128 !important;
}

.example-enter {
  opacity: 0.01;
}

.example-enter.example-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.example-leave {
  opacity: 1;
}

.example-leave.example-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}


.rotate90 {
  transform: rotate(90deg);
}

.getHeight {
  max-height: 1000px !important;
}
.stayLow {
  max-height: 0;
}

.rotate180 {
  transform: rotate(180deg);
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
.rotate-icon {
  animation: rotation 1.5s ease-out;
  animation-duration: 1s;
}

.rotate-wrapper {
  animation: rotation 2s ease-out;
  animation-duration: 1s;
}
 

.geoText {
  font-family: 'Geo', sans-serif;
}

p{
  display: flex;
  margin-block-start: 0px;
  margin-block-end: 0px;
}

h1, h2, h3, h4{
  display: flex;
  margin-block-start: 0px;
  margin-block-end: 0px;
}

.player-wrapper {
  position: relative;
  padding-top: 56.25% /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

button, input[type="submit"], input[type="reset"] {
	background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}